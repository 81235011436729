import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
  FaInstagram,
  FaSquareXTwitter,
} from "react-icons/fa6";

const navigation = {
  solutions: [
    { name: "Contact us", href: "/contact-us" },
    { name: "Jobs", href: "/jobs-and-internships" },
    { name: "Donate", href: "https://cafdonate.cafonline.org/19766" },
    { name: "Sign up to our emails", href: "/newsletter" },
  ],
  support: [
    { name: "Privacy policy", href: "/privacy-policy" },
    { name: "Safeguarding policy", href: "/safeguarding-policy" },
    { name: "Terms and conditions", href: "/terms-and-conditions" },
    { name: "Why trust us ", href: "/why-you-can-trust-avert" },
  ],

  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/AvertInfo",
      icon: (props) => (
        <FaFacebook
          className="inline-block w-6 h-6 text-white hover:text-gray-400"
          aria-hidden="true"
        />
      ),
    },
    {
      name: "Twitter",
      href: "https://twitter.com/Avert_info",
      icon: (props) => (
        <FaSquareXTwitter
          className="inline-block w-6 h-6 text-white hover:text-gray-400"
          aria-hidden="true"
        />
      ),
    },
    {
      name: "Linkedin",
      href: "https://www.linkedin.com/company/2593899",
      icon: (props) => (
        <FaLinkedin
          className="inline-block w-6 h-6 text-white hover:text-gray-400"
          aria-hidden="true"
        />
      ),
    },
    {
      name: "Youtube",
      href: "https://www.youtube.com/@Avert-info",
      icon: (props) => (
        <FaYoutube
          className="inline-block w-6 h-6 text-white hover:text-gray-400"
          aria-hidden="true"
        />
      ),
    },
    // {
    //   name: "Instagram",
    //   href: "#",
    //   icon: (props) => (
    //     <FaInstagram
    //       className="inline-block w-6 h-6 text-white hover:text-gray-400"
    //       aria-hidden="true"
    //     />
    //   ),
    // },
  ],
};

const Footer = () => {
  return (
    <footer className="bg-fossil" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <StaticImage
              className=""
              src="../images/avert-logo-slogan-white.svg"
              alt="Avert logo"
              placeholder="TRACED_SVG"
              layout="fixed"
              width={250}
              height={88}
            />
            {/* <p className="text-gray-500 text-base">
              Empowering people through knowledge
            </p> */}
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-24 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                {/* <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  title
                </h3> */}
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-white hover:text-gray-400"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                {/* <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  title
                </h3> */}
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base  text-white hover:text-gray-400"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2">
              <div className="space-y-1 col-span-2">
                <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
                  Powered by Avert:
                </h3>
              </div>

              <div className="space-y-1 col-span-1 p-3 pb-0">
                <a href="https://www.beintheknow.org" target="_blank">
                  <StaticImage
                    className=""
                    src="../images/bitk-logo-yellow.svg"
                    alt="BITK logo"
                    placeholder="TRACED_SVG"
                    layout="fixed"
                    width={118}
                    height={65}
                  />
                </a>
              </div>
              <div className="space-y-1 col-span-1 p-3 pt-0">
                <a href="https://boost.avert.org/" target="_blank">
                  <StaticImage
                    className=""
                    src="../images/boost-logo.svg"
                    alt="Boost logo"
                    placeholder="TRACED_SVG"
                    layout="fixed"
                    width={118}
                    height={61}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8  pt-0">
          <p className="text-xs text-white">
            All material on www.avert.info is copyright Avert (unless stated
            otherwise). All rights reserved. Registered UK charity number:
            1074849. Registered UK company number: 3716796.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
